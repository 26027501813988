import { RxStompConfig } from '@stomp/rx-stomp';
import SockJS from 'sockjs-client';
import { environment } from 'src/environments/environment';

let apiUrl = environment.api;
let socketEndpoint = apiUrl + "/public/stomp";//environment.websocketUrl + environment.api + environment.websocketEndpoint;

export const myRxStompConfig: RxStompConfig = {

  // Which server?
  // brokerURL: socketEndpoint,
  webSocketFactory: function () {
    return new SockJS(socketEndpoint);
  },
  // Headers
  // Typical keys: login, passcode, host
//   connectHeaders: {
//     login: 'guest',
//     passcode: 'guest',
//   },

  // How often to heartbeat?
  // Interval in milliseconds, set to 0 to disable
  heartbeatIncoming: 0, // Typical value 0 - disabled
  heartbeatOutgoing: 20000, // Typical value 20000 - every 20 seconds

  // Wait in milliseconds before attempting auto reconnect
  // Set to 0 to disable
  // Typical value 500 (500 milli seconds)
  reconnectDelay: 200,

  // Will log diagnostics on console
  // It can be quite verbose, not recommended in production
  // Skip this key to stop logging to console
  debug: (msg: string): void => {
    console.info(new Date(), msg);
  },
};