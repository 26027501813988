import { Component, Input, OnInit, ChangeDetectionStrategy, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import Graphic from '@arcgis/core/Graphic';
import WebMap from '@arcgis/core/WebMap';
import Config from "@arcgis/core/config";
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer';
import SimpleMarkerSymbol from '@arcgis/core/symbols/SimpleMarkerSymbol';
import MapView from '@arcgis/core/views/MapView';
import { defineCustomElements } from '@arcgis/map-components/dist/loader';
import { MessageService } from 'primeng/api';
import { LoadingService } from 'src/app/service/loading/loading-service.service';
import { Denuncia } from '../atendimento/atendimento-anonimo/denuncia';
import { AtendimentoAnonimoService } from '../atendimento/atendimento-anonimo/service/atendimento-anonimo.service';
import { MapService } from './map.service';
import { EnderecoSetor } from '../common/entities/endereco-setor';
import { MatDialog } from '@angular/material/dialog';
import { ModalConfirmacao } from '../common/modal/modal-confirmacao.component';


@Component({
  selector: 'app-map',
  standalone: true,
  templateUrl: './map.component.html',
  styleUrl: './map.component.scss'
})
export class MapComponent implements OnInit {

  @Input() setorCentroidMapa!: EnderecoSetor;

  readonly dialog = inject(MatDialog);

  denuncia!: Denuncia;
  latitute: number;
  longitude: number;
  mapaCarregado = false;
  mapa!: WebMap;
  view!: MapView;
  zoom = 11;
  center = [-47.827707, -15.792367];
  erroAoRecuperarEndereco: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private service: MapService,
    private atendimentoAnonimoService: AtendimentoAnonimoService,
    private loader: LoadingService,
    private messageService: MessageService,
    private router: Router
  ) { }

  ngOnInit(): void {

    Config.apiKey = 'AAPKaa6b119b54a341e0b40063d7128300290cZ3LC7nVZpHTLc0fJUhENS_d3HxF1470G3OryiyshARR6srCDmyflyma87U4FSE';

    defineCustomElements(window, { resourcesUrl: "https://js.arcgis.com/map-components/4.30/assets" });

    this.load().then(() => this.view.on("click", (event: any) => this.selecionarPonto(event)));

  }

  async load() {

    const data = await this.service.loadMap(this.setorCentroidMapa);

    this.mapa = data.map;
    this.view = data.view;
    this.mapaCarregado = true;

  }

  selecionarPonto(event: any) {

    this.mapa.layers.removeAll();

    const geometry = this.view.toMap({x: event.x, y: event.y});
    const graphicslayer = this.criarGraphicLayer('selecao_usuario', geometry);

    this.mapa.add(graphicslayer);
    this.atualizarLatitudeLongitude(geometry);
    this.consultarEnderecoPelasCoordenadas();

  }

  private criarGraphicLayer(id: string, geometry: __esri.Point): GraphicsLayer {
    return new GraphicsLayer({
      id: id,
      graphics: this.criarGraphic(geometry)
    });
  }

  private criarGraphic(geometry: __esri.Point): Graphic[] {
    return [new Graphic({
      geometry,
      symbol: this.getSimpleMarkerSymbol()
    })];
  }

  private getSimpleMarkerSymbol(): SimpleMarkerSymbol {
    return new SimpleMarkerSymbol({
      size: '20px',
      color: 'red',
      outline: {
        color: 'white',
        width: 1
      }
    });
  }

  private atualizarLatitudeLongitude(geometry: __esri.Point): __esri.Point {
    this.latitute = geometry.latitude;
    this.longitude = geometry.longitude;
    return geometry;
  }

  private consultarEnderecoPelasCoordenadas(): any {
    this.loader.show();

    this.atendimentoAnonimoService.getReverseGeocode(this.getGeolocationPosition()).subscribe({
      next: (data: any) => {
        this.service.geocodeReverso = data;
        this.erroAoRecuperarEndereco = false;

        this.openDialog(data);
      },
      error: (error: any) => {
        this.erroAoRecuperarEndereco = true;
        console.error("Erro ao buscar endereço pelo ReverseGeocode.", error);
      },
      complete: () => {
        // this.notificarSelecao(this.service.geocodeReverso?.address?.LongLabel ?? '');
        this.loader.hide();
      }
    });
  }

  private getCoordenadas(): GeolocationCoordinates {
    return {
      latitude: this.latitute,
      longitude: this.longitude,
      accuracy: 1,
      altitude: null,
      altitudeAccuracy: null,
      heading: null,
      speed: null
    };
  }

  private getGeolocationPosition(): GeolocationPosition {
    return {
      coords: this.getCoordenadas(),
      timestamp: new Date().getTime()
    }
  }

  // private notificarSelecao(descricao: string): void {
  //   this.messageService.add({
  //     severity: 'info',
  //     summary: 'LOCAL SELECIONADO',
  //     detail: descricao,
  //     closable: true,
  //     life: 20000
  //   });
  // }

  openDialog(data: any) {
    const dialogRef = this.dialog.open(ModalConfirmacao, {data});

    const origin = btoa('mapa')

    dialogRef.afterClosed()
    .subscribe((result) => {

      if(result === 'disparar_evento') {

        const event = new Event('atualizao_endereco')

        document.dispatchEvent(event);
      }
    });
  }

}
