<header
    [class.component-dark-theme]="themeService.isDark()"
    [class.right-sidebar]="themeService.isRightSidebar()"
    [class.hide-sidebar]="themeService.isHideSidebar()"
    [class.dark-header]="themeService.isHeaderDark()"
    [class.card-borderd-theme]="themeService.isCardBorder()"
    [class.card-border-radius]="themeService.isCardBorderRadius()"
    [class.rtl-enabled]="themeService.isRTLEnabled()"
>
<div class="flex-container">
    <div class="row">
        <div class="logo col-lg-6 flex-item">
            <a href="https://www.pmdf.df.gov.br/">
                <img width="501" height="250" src="https://www.pmdf.df.gov.br/wp-content/uploads/2024/04/RIV_PMDF_2022_Marca_PMDF.svg" class="attachment-full size-full wp-image-3893" alt="">
            </a>
        </div>
        <div class="denuncia-logo col-lg-6 flex-item">
                <img src="/assets/img/topo_b.png">
        </div>
    </div>
</div>
</header>