<div #loading id="loading" [ngClass]="{'d-flex justify-items-center align-items-center':true,'d-none':!show, 'd-block': show}">
    <svg class="splash-spinner" viewBox="0 0 50 50">
      <circle
        class="path"
        cx="25"
        cy="25"
        r="20"
        fill="none"
        stroke-width="5"
      ></circle>
    </svg>
  </div>
  