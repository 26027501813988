<p-toast position="top-right" [baseZIndex]="1060"></p-toast>
<div
    [class.dark-theme]="isDark"
    [class.card-borderd-theme]="isCardBorder"
    [class.card-border-radius]="isCardBorderRadius"
    [class.rtl-enabled]="isRTLEnabled"
>
    <div
        [ngClass]="{'active': isToggled}"
        [class.right-sidebar]="isRightSidebar"
        [class.hide-sidebar]="isHideSidebar"
    >
        <app-header></app-header>
        <router-outlet></router-outlet>
        <div class="flex-grow-1"></div>
    </div>
</div>