export class Formatador {
  static formatarPerfil(tipo: number): string {
    switch (tipo) {
      case 1:
        return "Administrador Geral";
      case 2:
        return "Administrador";
      case 3:
        return "Usuário";
      case 4:
        return "Atendente";
      case 5:
        return "Despachante";
      case 6:
        return "Rádio Operador";
      default:
        return "Desconhecido";
    }
  }

  static toTitleCase(texto: string | null): string {
    if (texto == null || texto.trim() == "") {
      return "";
    }

    return texto
      .trim()
      .toLowerCase()
      .split(" ")
      .map((minusculo: string) => minusculo[0].toUpperCase() + minusculo.substring(1))
      .join(" ");
  }

  static iniciarMaiuscula(texto: string | null): string {
    if (texto == null || texto.trim() == "") {
      return "";
    }

    const minuscula: string = texto.trim().toLowerCase();
    return minuscula[0].toUpperCase() + minuscula.substring(1);
  }
}