<div class="main">
    <mat-card class="mb-25 atendimento-card d-flex flex-column align-items-center">
        <img class="mb-25" src="assets/img/icon/icons8-success-80.png" alt="success">
        <mat-card-header>
            <h5 class="mb-25">Atendimento criado com sucesso.</h5>
        </mat-card-header>
        <mat-card-content>
            <mat-card-header>
                <h5 class="mb-25">Protocolo: {{protocolo}}.</h5>
            </mat-card-header>
        </mat-card-content>
        <button mat-flat-button color="accent" (click)="reiniciar()">Finalizar</button>
    </mat-card>
</div>
