<div
    class="sidebar-area bg-white box-shadow"
    [ngClass]="{'active': isToggled}"
    [class.dark-sidebar]="themeService.isSidebarDark()"
    [class.right-sidebar]="themeService.isRightSidebar()"
    [class.hide-sidebar]="themeService.isHideSidebar()"
>
    <div class="logo bg-white">
        <a routerLink="/" class="d-flex align-items-center">
            <img src="assets/img/logo_pmdf.png" alt="logo" class="logoPmdf">
            <h2 class="mb-0 me-30 titulo">Atendimento 190</h2>
        </a>
    </div>
    <div class="burger-menu" (click)="toggle()" [ngClass]="{'active': isToggled}">
        <span class="top-bar"></span>
        <span class="middle-bar"></span>
        <span class="bottom-bar"></span>
    </div>
    <br>
    <br>
    <ng-scrollbar visibility="hover" style="height: 100vh;">
        <div class="sidebar-inner">
            <div class="sidebar-menu">
                <mat-accordion>
                    <!-- <span class="sub-title gray-color">Principal</span> -->
                    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = true" *ngIf="userRoles.includes('ATENDIMENTO')">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <i class="flaticon-menu-1"></i>
                                <span class="title">Atendimento</span>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <ul class="sidebar-sub-menu">
                            <li class="sidemenu-item">
                                <a routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                                    Painel Atendimento
                                </a>
                            </li>
                            <li class="sidemenu-item">
                                <a routerLink="/atendimentos" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                                    Antedimentos
                                </a>
                            </li>
                        </ul>
                    </mat-expansion-panel>
                    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = true" *ngIf="userRoles.includes('UNIDADE')">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <i class="ri-alarm-warning-line"></i>
                                <span class="title">Unidade</span>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <ul class="sidebar-sub-menu">
                            <li class="sidemenu-item">
                                <a routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                                    Serviço
                                </a>
                            </li>
                            <li class="sidemenu-item">
                                <a routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                                    Viaturas
                                </a>
                            </li>
                        </ul>
                    </mat-expansion-panel>

                    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = true" *ngIf="userRoles.includes('SISTEMAS')">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <i class="flaticon-menu-1"></i>
                                <span class="title">Sistemas</span>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <ul class="sidebar-sub-menu">
                            <li class="sidemenu-item">
                                <a routerLink="mapa" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                                    Mapas
                                </a>
                            </li>
                        </ul>
                    </mat-expansion-panel>

                    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = true" *ngIf="userRoles.includes('DENUNCIAS')">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <i class="flaticon-technical-support"></i>
                                <span class="title">Denúncias</span>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <ul class="sidebar-sub-menu">
                            <li class="sidemenu-item">
                                <a routerLink="anonimo/atendimento-web/listar" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                                    Lista de Denúncias
                                </a>
                            </li>
                        </ul>
                    </mat-expansion-panel>


                    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = true" *ngIf="userRoles.includes('ADMINISTRACAO')">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <i class="ri-building-line"></i>
                                <span class="title">Administração</span>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <ul class="sidebar-sub-menu">
                            <li class="sidemenu-item">
                                <a routerLink="/usuario" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                                    Usuario
                                </a>
                            </li>
                            <li class="sidemenu-item">
                                <a routerLink="/batalhao" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                                    Batalhões
                                </a>
                            </li>
                            <li class="sidemenu-item">
                                <a routerLink="/mesa" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                                    Mesas
                                </a>
                            </li>
                            <li class="sidemenu-item">
                                <a routerLink="/natureza" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                                    Natureza
                                </a>
                            </li>
                        </ul>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </div>
    </ng-scrollbar>
</div>