import { Directive, ElementRef, OnInit } from '@angular/core';

@Directive({
  selector: '[numbers-only]',
  standalone: true,
})
export class NumbersOnlyDirective implements OnInit {

  constructor(private el: ElementRef) { }

  ngOnInit(): void {
    this.el.nativeElement.addEventListener('keyup', (data: any) => {
      this.el.nativeElement.value = this.formatInput(data.target.value);
    })
  }

  formatInput(value: string): string {
    const pattern = /([^0-9])/g;
    return value.replaceAll(pattern, '');
  }

}
