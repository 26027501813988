<div *ngIf="!exibindoMapa" class="main">
    <header>
        <h2>Dados da Ocorrência</h2>
    </header>
    <mat-card class="mb-25 create-project-card atendimento-card view-size">
        <mat-stepper
            [linear]="isLinear"
            #stepper
            [orientation]="(stepperOrientation | async)!"
        >
            <mat-step [stepControl]="segundoForm" label="Endereço">
                <form [formGroup]="segundoForm">
                    <div class="flex-w-space-between">
                        
                        <div class="col-lg-6">
                            <mat-form-field
                                appearance="outline"
                                class="size-full-width mat-form-field-cidade"
                                matTooltip="Selecione um município"
                            >
                                <mat-label>Cidade do Distrito Federal</mat-label>
                                <input type="text"
                                    placeholder="Digite a cidade e selecione"
                                    required
                                    matInput
                                    formControlName="cidade"
                                    [matAutocomplete]="autoCidade"
                                >
                                <mat-autocomplete #autoCidade="matAutocomplete" (optionSelected)="cidadeSelect($event)">
                                    <mat-option *ngFor="let ra of rasFiltradas" [value]="ra.descricao">
                                        {{ ra.descricao }}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                        </div>

                        <div class="col-lg-6">
                            <mat-form-field
                            appearance="outline"
                            class="size-full-width"
                            matTooltip="Selecione um Bairro"
                            >
                                <mat-label>Bairro</mat-label>
                                <input type="text"
                                    placeholder="Digite o bairro e selecione"
                                    required
                                    matInput
                                    formControlName="bairro"
                                    [matAutocomplete]="autoBairro"
                                >
                                <mat-autocomplete #autoBairro="matAutocomplete" (optionSelected)="setorSelect($event)">
                                    <mat-option *ngFor="let setor of setoresFiltrados" [value]="setor.stDescricao">
                                        {{ setor.stDescricao }}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="flex-w-space-between">
                        <mat-form-field
                            appearance="outline"
                            class="size-full-width"
                            matTooltip="Digite o endereço"
                        >
                            <mat-label>Endereço</mat-label>
                            <input
                                matInput
                                formControlName="endereco"
                                placeholder="Digite o endereço"
                                required
                            />
                        </mat-form-field>

                        <mat-form-field
                            appearance="outline"
                            class="size-sm"
                            matTooltip="Digite o número do local"
                        >
                            <mat-label>Nr</mat-label>
                            <input
                                matInput
                                formControlName="numero"
                                required
                                numbers-only
                            />
                        </mat-form-field>
                    </div>

                    <div class="flex-w-space-between">
                        <mat-form-field
                            appearance="outline"
                            class="size-full-width"
                            matTooltip="Digite o complemento"
                        >
                            <mat-label>Complemento</mat-label>
                            <input
                                matInput
                                formControlName="complemento"
                                placeholder="Digite um complemento"
                            />
                        </mat-form-field>
                    </div>

                    <div class="flex-w-space-between">
                        <mat-form-field
                            appearance="outline"
                            class="size-full-width"
                            matTooltip="Digite um ponto de referência"
                        >
                            <mat-label>Ponto de Referência</mat-label>
                            <input
                                matInput
                                formControlName="pontoReferencia"
                                placeholder="Digite um ponto de referência"
                                maxlength="255"
                            />
                        </mat-form-field>
                    </div>

                    <div class="msg-local" *ngIf="exibirLabelLocalSelecionado">
                        <p>{{ labelLocalSelecionado }}</p>
                    </div>

                    <div class="d-flex justify-content-end">
                        <button pButton class="p-button-success" [disabled]="disabledButtonMapa()" (click)="exibirMapa()" [label]="textoBotaoMapa"></button>
                    </div>

                    <div class="button-avancar">
                        <button
                            mat-raised-button
                            color="primary"
                            matStepperNext
                            [disabled]="segundoForm.invalid"
                            matTooltip="Confira o preenchimento"
                            matTooltipPosition="left"
                        >
                            Avançar
                        </button>
                    </div>
                </form>
            </mat-step>

            <mat-step [stepControl]="terceiroForm" label="Origem">
                <label>Questionário da ocorrência</label>
                <form [formGroup]="terceiroForm">
                    <div>
                        <div>
                            <label>1. De onde vem o barulho?</label>
                        </div>
                        <mat-form-field
                            appearance="outline"
                            class="size-md"
                            matTooltip="Selecione a origem do barulho"
                            style="padding-top: 10px"
                        >
                            <mat-label>Origem</mat-label>
                            <mat-select
                                formControlName="origemOcorrencia"
                                placeholder="Selecione"
                                required
                                (selectionChange)="buscarMotivo($event.value)"
                            >
                                <mat-option
                                    *ngFor="let origem of origem"
                                    [value]="origem"
                                    >{{ origem.descricao }}</mat-option
                                >
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div>
                        <div>
                            <label *ngIf="terceiroForm.controls.origemOcorrencia.value">2. Motivo?</label>
                        </div>
                        <mat-form-field
                            appearance="outline"
                            *ngIf="terceiroForm.controls.origemOcorrencia.value"
                            style="padding-top: 10px"
                            class="size-md"
                            matTooltip="Selecione o motivo do barulho"
                        >
                            <mat-label>Motivo</mat-label>
                            <mat-select
                                formControlName="tipo"
                                placeholder="Selecione"
                                required
                            >
                                <mat-option
                                    *ngFor="let motivo of motivo"
                                    [value]="motivo"
                                    >{{ motivo.descricao }}</mat-option
                                >
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="button-many">
                        <button
                            mat-raised-button
                            color="warn"
                            matStepperPrevious
                            matTooltip="Voltar"
                            matTooltipPosition="right"
                        >
                            Voltar
                        </button>
                        <button
                            mat-raised-button
                            color="primary"
                            matStepperNext
                            [disabled]="
                                segundoForm.invalid || terceiroForm.invalid
                            "
                            matTooltip="Confira o preenchimento"
                            matTooltipPosition="left"
                        >
                            Avançar
                        </button>
                    </div>
                </form>
            </mat-step>

            <mat-step [stepControl]="quartoForm" label="Informações">
                <form [formGroup]="quartoForm">
                    <label>Complemento da Ocorrência</label>
                    <div class="padding-top">
                        <mat-form-field
                            appearance="outline"
                            class="size-full-width"
                            required
                        >
                            <mat-label>Complemento</mat-label>
                            <textarea
                                matInput
                                #message
                                rows="5"
                                formControlName="complementoOcorrencia"
                                maxlength="1000"
                                placeholder="'Ex: Os moradores da casa tal estão com o som alto, incomodando a vizinhança...'"
                                (blur)="complementoOcorrenciaBlur($event)"
                            ></textarea>
                            <mat-hint align="start"
                                ><strong>(mínimo de 10 caracteres)</strong>
                            </mat-hint>
                            <mat-hint align="end"
                                >{{ message.value.length }} / 1000</mat-hint
                            >
                        </mat-form-field>
                    </div>

                    <div class="button-many">
                        <button
                            mat-raised-button
                            color="warn"
                            matStepperPrevious
                            matTooltip="Voltar"
                            matTooltipPosition="right"
                        >
                            Voltar
                        </button>
                        <button
                            mat-raised-button
                            color="primary"
                            matStepperNext
                            [disabled]="
                                segundoForm.invalid ||
                                terceiroForm.invalid ||
                                quartoForm.invalid
                            "
                            matTooltip="Confira o preenchimento"
                            (click)="montarResumoForm()"
                            matTooltipPosition="left"
                        >
                            Avançar
                        </button>
                    </div>
                </form>
            </mat-step>

            <mat-step [stepControl]="resumoForm" label="Resumo">
                <p
                    class="mt-25 mb-25 fw-bold d-flex flex-column align-items-center"
                    style="color: green"
                >
                    Verifique atentamento as informações fornecidas.
                </p>
                <form [formGroup]="resumoForm" (submit)="enviar()">
                    <div class="flex-w-space-between">
                        <mat-form-field appearance="outline" class="size-full-width">
                            <mat-label>Cidade</mat-label>
                            <input matInput formControlName="cidade" readonly />
                        </mat-form-field>
                    </div>

                    <div class="flex-w-space-between">
                        <mat-form-field
                            appearance="outline"
                            class="size-full-width"
                        >
                            <mat-label>Bairro</mat-label>
                            <input matInput formControlName="bairro" readonly />
                        </mat-form-field>
                    </div>

                    <div class="flex-w-space-between">
                        <mat-form-field
                            appearance="outline"
                            class="size-full-width"
                        >
                            <mat-label>Endereço</mat-label>
                            <input
                                matInput
                                formControlName="endereco"
                                readonly
                            />
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="size-sm">
                            <mat-label>Nr</mat-label>
                            <input matInput formControlName="numero" readonly />
                        </mat-form-field>
                    </div>

                    <mat-form-field
                        appearance="outline"
                        class="size-full-width"
                    >
                        <mat-label>Complemento</mat-label>
                        <input
                            matInput
                            formControlName="complemento"
                            readonly
                        />
                    </mat-form-field>

                    <div class="flex-w-space-between">
                        <mat-form-field appearance="outline" class="size-full-width">
                            <mat-label>Ponto de Referência</mat-label>
                            <input matInput formControlName="pontoReferencia" readonly />
                        </mat-form-field>
                    </div>

                    <div>
                        <mat-form-field
                            appearance="outline"
                            class="size-full-width"
                        >
                            <mat-label>Origem do atendimento</mat-label>
                            <input
                                matInput
                                formControlName="origemOcorrencia"
                                readonly
                            />
                        </mat-form-field>
                        <mat-form-field
                            appearance="outline"
                            class="size-full-width"
                        >
                            <mat-label>Tipo do atendimento</mat-label>
                            <input matInput formControlName="tipo" readonly />
                        </mat-form-field>
                    </div>

                    <div>
                        <mat-form-field
                            appearance="outline"
                            class="size-full-width"
                        >
                            <mat-label>Complemento do atendimento</mat-label>
                            <textarea
                                style="resize: none"
                                matInput
                                #message
                                rows="3"
                                formControlName="complementoOcorrencia"
                                readonly
                            ></textarea>
                        </mat-form-field>
                    </div>

                    <div class="mb-30">
                        <section class="example-section">
                            <mat-checkbox
                                formControlName="declaracaoVeracidade"
                                required
                                >Declaro sob as penas da Lei que as informações
                                fornecidas aqui são verdadeiras.</mat-checkbox
                            >
                        </section>
                    </div>

                    <div class="button-many">
                        <button
                            mat-raised-button
                            color="warn"
                            matStepperPrevious
                        >
                            Voltar
                        </button>
                        <button
                            mat-raised-button
                            color="primary"
                            [disabled]="
                                segundoForm.invalid ||
                                terceiroForm.invalid ||
                                quartoForm.invalid ||
                                resumoForm.invalid
                            "
                            (submit)="enviar()"
                            matTooltip="Confira antes de enviar"
                            matTooltipPosition="left"
                        >
                            Enviar
                        </button>
                    </div>
                </form>
            </mat-step>
        </mat-stepper>
    </mat-card>
</div>
<div *ngIf="exibindoMapa" style="max-width: 100%; height: 100dvh">
    <app-map [setorCentroidMapa]="getSetorCentroidMapa()"></app-map>
</div>