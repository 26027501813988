import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";

@Injectable({
    providedIn: "root",
  })
  export class LoadingService {

    private sub?:Subject<boolean>;
  
    init(sub: Subject<boolean>) {
      this.sub = sub;
    }
  
    show() {
      this.sub?.next(true);
    }
  
    hide() {
      this.sub?.next(false);
    }
  }
  