import { Injectable } from '@angular/core';
import WebMap from '@arcgis/core/WebMap';
import MapView from '@arcgis/core/views/MapView';
import Search from '@arcgis/core/widgets/Search';
import { RetornoReverseGeocode } from '../common/arcgis/dto/retorno-reverse-geocode';
import { EnderecoSetor } from '../common/entities/endereco-setor';

@Injectable({
  providedIn: "root",
})
export class MapService {

  geocodeReverso!: RetornoReverseGeocode;
  latitudeCentral!: number;
  longitudeCentral!: number;

  async loadMap(setorCentroidMapa: EnderecoSetor): Promise<any> {

    const map = new WebMap({
      basemap: "arcgis-imagery"
    });

    await map.loadAll();

    let view!: MapView;

    view = new MapView({
      map: map,
      container: "mapView",
      center: [setorCentroidMapa?.stY, setorCentroidMapa?.stX],
      zoom: 15,
    });

    var searchWidget = new Search({
      view: view
    });

    view.ui.add(searchWidget, 'top-right')

    return { view, map };
  }

}
