import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, finalize } from "rxjs";
import { LoadingService } from "src/app/service/loading/loading-service.service";

@Injectable()
export class NetworkInterceptor implements HttpInterceptor {
    constructor(private loader: LoadingService) {}
    totalRequests = 0;
    completedRequests = 0;
    intercept(
      request: HttpRequest<unknown>,
      next: HttpHandler
    ): Observable<HttpEvent<unknown>> {
      this.loader.show();
      this.totalRequests++;

       return next.handle(request).pipe(
      finalize(() => {
        this.completedRequests++;

        if (this.completedRequests === this.totalRequests) {
          this.loader.hide();
          this.completedRequests = 0;
          this.totalRequests = 0;
        }
      })
    );
    }
}
