import {FormControl, FormGroup} from "@angular/forms";
import {HttpErrorResponse} from "@angular/common/http";
import { MessageService}  from "primeng/api";

export class FormComponent {
  constructor (private message: MessageService) { }

  validate(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched();
        control.updateValueAndValidity();
      } else if (control instanceof FormGroup) {
        this.validate(control);
      }
    });
  }

  compareWithCodigo(obj1: any, obj2: any): boolean {
    return obj1 && obj2 && obj1.codigo === obj2.codigo;
  }

  compareWithId(obj1: any, obj2: any): boolean {
    return obj1 && obj2 && obj1.id === obj2.id;
  }

  setFormErrors(form: FormGroup, httpError: HttpErrorResponse) {
    if (httpError.status === 400 && httpError.error) {
      if (!httpError.error.message) {
        Object.entries(httpError.error).forEach(error => {
          const control = form.controls[error[0]];
          control.setErrors({incorrect: true, message: error[1]});
          control.markAsTouched();
        })
      }
    }
  }

  showErrorMessage(titulo: string, descricao: string){
    this.message.add({
      severity: 'error',
      summary: titulo,
      detail: descricao
    });
  }

  showSucessMessage(titulo: string, descricao: string){
    this.message.add({
      severity: 'success',
      summary: titulo,
      detail: descricao
    });
  }

  showWarningMessage(titulo: string, descricao: string){
    this.message.add({
      severity: 'warn',
      summary: titulo,
      detail: descricao
    });
  }

  showInfoMessage(titulo: string, descricao: string){
    this.message.add({
      severity: 'info',
      summary: titulo,
      detail: descricao
    });
  }

}
