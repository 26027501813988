import {ChangeDetectionStrategy, Component, Inject, inject} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'modal-confirmacao',
  templateUrl: 'modal-confirmacao.component.html',
  standalone: true,
  imports: [MatDialogModule, MatButtonModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalConfirmacao {

  dataIn!: any;

  constructor(
    public dialogRef: MatDialogRef<ModalConfirmacao>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {

    this.dataIn = this.data;

  }

  decidirAcao(event: any) {
    if(event === 'sim') {
      this.dialogRef.close("disparar_evento");
    } else {
      this.dialogRef.close();
    }
  }

}