import {CUSTOM_ELEMENTS_SCHEMA, Component} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatRadioModule} from '@angular/material/radio';
import {CommonModule} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';


@Component({
  selector: 'app-sucesso-cadastro',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatButtonModule,
    MatIconModule,
    MatRadioModule,
    CommonModule
  ],
  templateUrl: './sucesso-cadastro.component.html',
  styleUrls: ['./sucesso-cadastro.component.scss'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SucessoCadastroComponent {

  protocolo!: string;

  constructor(private route: ActivatedRoute, private router: Router) {
    this.protocolo = this.route.snapshot.paramMap.get('protocolo') || '';
  }

  reiniciar() {
    this.router.navigate(['/inicio'], {replaceUrl: true});
  }

}
