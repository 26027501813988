<div class="h-100vh pt-50 pb-50">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="internal-error text-center">
                <h1 class="mb-25">Ops!</h1>
                <h4 class="mb-15 ms-auto me-auto">{{mensagem}}</h4>
                <a mat-raised-button class="atendimento mt-10" routerLink="/">Ir para o início.</a>
            </div>
        </div>
    </div>
</div>