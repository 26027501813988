<div class="main">
    <div class="center card flex justify-content-center">
        <mat-card class="mb-25 create-project-card atendimento-card">

            <div class="page">
                <p>Por meio deste canal será possível o registro de denúncia de perturbação do trabalho ou sossego alheios.</p>
                <p>Você sabe qual a diferença entre o crime ambiental de poluição sonora e a contravenção penal de perturbação do sossego e em qual órgão denunciar?</p>
            
                <h5>O QUE É POLUIÇÃO SONORA? (SOM COMERCIAL/INDUSTRIAL)</h5>
                <p>
                A <u><b>POLUIÇÃO SONORA</b></u> é uma infração administrativa ambiental, onde sons mecânicos
                ou ao vivo, produzidos nas atividades de bares, restaurantes, igrejas, shows, academias,
                clubes, dentre outros, emitidos acima dos níveis tolerados por lei e nocivos à saúde, à
                segurança e ao bem-estar da coletividade, são passíveis de multa (De R$ 200,00 a
                R$ 20.000,00), e <b>QUEM ATUA É O BRASÍLIA AMBIENTAL (Telefone 162)</b> (Lei nº
                4092/2008).
                </p>
                <p>
                Ou seja, quando os sons altos <b>são produzidos por atividades comerciais ou industriais</b>, quem age é o <b>BRASÍLIA AMBIENTAL</b> (Secretaria do Meio Ambiente), que é
                o órgão que detém a competência para MULTAR, INTERDITAR, SUSPENDER ou
                CASSAR a licença da empresa causadora da infração administrativa. A denúncia será
                realizada <b>através do telefone 162</b>, onde a vítima não precisará se identificar para fazer o
                registro.
                </p>
                <p>O horário de atendimento da <b>Central 162</b> é de segunda a sexta das 7h às 21h.</p>
                <p>Sábado, domingo e feriados das 8h às 18h. Ligação gratuita para telefone fixo e celular.</p>
            
                <h5>O QUE É PERTURBAÇÃO DO SOSSEGO?</h5>
                <p>
                <u><b>PERTURBAÇÃO DO SOSSEGO ALHEIO</b></u> é uma contravenção penal, ou seja, <b>uma
                infração criminal</b>, que consiste em perturbar alguém, tanto no trabalho quanto no sossego
                alheio, com gritaria ou algazarra, exercendo profissão incômoda ou ruidosa, abusando de
                instrumentos sonoros (sons mecânicos fixos, sons móveis automotivos ou sons ao vivo), ou
                por barulhos provocados por animais de estimação (latidos de cachorros), e são passíveis de
                prisão simples e multa.
                </p>
                <p><b>Quem atua é a Polícia Militar do DF (Telefone 190) ou a Polícia Civil do DF (Telefone 197)</b> (Lei de Contravenções Penais nº3688/1941, art. 42).</p>
                <p>
                Cumprindo o <b>ACÓRDÃO do TJDFT nº 1425679</b>, o TCO somente será aceito pelo Juizado da cidade, se o policial da viatura lançar, pelo menos (01) UMA VÍTIMA da Perturbação. Ou seja, o solicitante deverá se identificar ao policial e assinar o TERMO CIRCUNSTANCIADO DE OCORRÊNCIA (TCO - PMDF), <b>onde <u>não é possível a atuação da PMDF SEM O DENUNCIANTE</u></b>.
                </p>
                <p>
                A perturbação do trabalho ou do sossego alheios é uma Contravenção Penal prevista no art.
                42 do Decreto-Lei n. 3.688/41.
                </p>
                <p>“Art. 42. Perturbar alguém o trabalho ou o sossego alheios:</p>
                <p>I – com gritaria ou algazarra;</p>
                <p>II – exercendo profissão incômoda ou ruidosa, em desacordo com as prescrições legais;</p>
                <p>III – abusando de instrumentos sonoros ou sinais acústicos;</p>
                <p>IV – provocando ou não procurando impedir barulho produzido por animal de que tem a guarda:</p>
                <p>Pena – prisão simples, de quinze dias a três meses, ou multa, de duzentos mil réis a dois contos de réis.”</p>
                <p>
                A Perturbação do Sossego alheio pode ser configurada em qualquer horário do dia ou da
                noite. A pessoa que sentir-se perturbada, seja com som de veículos, gritarias, algazarras em
                bares e festas em casas ou condomínios, poderá fazer o registro da denúncia através desse
                canal.
                </p>
                <p>
                Caso a perturbação sonora seja ocasionada por veículo(s) é importante que as características
                deste sejam informadas, tais como: placa, cor, marca/modelo.
                </p>

                <div class="center card flex justify-content-center m-30 p-25 alerta">
                    <p>Atenção!<br>
                    Comunicação falsa de crime ou de contravenção à Polícia é crime e tem pena prevista no artigo 340 do Código Penal Brasileiro.<br>
                    </p>
                </div>

                <p>Seja bem-vindo ao portal. Clique no botão abaixo para iniciar o registro do atendimento.</p>

                <div class="d-flex flex-column align-items-center m-25">
                    <p class="m-0">
                        <p-button (click)="redirect()"> Iniciar </p-button>
                    </p>
                </div>

            </div>
        </mat-card>
    </div>
</div>