import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Cidade } from 'src/app/components/common/entities/cidade';
import { EnderecoSetor } from 'src/app/components/common/entities/endereco-setor';
import { Estado } from 'src/app/components/common/entities/estado';
import { RegiaoAdministrativa } from 'src/app/components/common/entities/regiao-administrativa';
import { environment } from 'src/environments/environment';
import { Denuncia } from '../denuncia';

@Injectable({
    providedIn: 'root',
})
export class AtendimentoAnonimoService {
    private url = environment.api;

    constructor(private http: HttpClient) {}

    getReverseGeocode(localizacaoAtual: GeolocationPosition) {
        return this.http.get(
            'https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/reverseGeocode',
            {
                params: {
                    location: `${localizacaoAtual.coords.longitude}, ${localizacaoAtual.coords.latitude}`,
                    f: 'json',
                    token: environment.apikey,
                },
            }
        );
    }

    getAddressByCep(cep: string) {
        return this.http.get(
            `${this.url}/v1/cep/buscar-cep/${cep
                .replaceAll('-', '')
                .padStart(8, '0')}`
        );
    }

    getEstados(): Observable<Estado[]> {
        return this.http.get(`${this.url}/v1/estado/all`) as Observable<Estado[]>;
    }

    getCidadesPorEstado(id: number): Observable<Cidade> {
        return this.http.get(`${this.url}/v1/cidade/cidades-por-estado/${id}`) as Observable<Cidade>;
    }

    getRAsDF(): Observable<RegiaoAdministrativa> {
        return this.http.get(`${this.url}/v1/regiao-administrativa/listar-todas`) as Observable<RegiaoAdministrativa>;
    }

    getOrigemDenuncia(): Observable<any> {
        return this.http.get(`${this.url}/v1/denuncia/origem`) as Observable<any>;
    }

    getMotivoDenuncia(idOrigem: number): Observable<any> {
        return this.http.get(`${this.url}/v1/denuncia/motivo/${idOrigem}`) as Observable<any>;
    }

    salvarDenuncia(denuncia: Denuncia): Observable<any> {
        return this.http.post(
            `${this.url}/v1/denuncia/`,
            denuncia
        ) as Observable<any>;
    }

    getSituacoes(): Observable<any> {
        return this.http.get(`${this.url}/v1/denuncia/situacao`) as Observable<any>;
    }

    getSetoresDeRACom(id: number): Observable<EnderecoSetor[]> {
        return this.http.get(`${this.url}/v1/endereco-setor/listar-setores?raId=${id}`) as Observable<EnderecoSetor[]>;
    }

}
