import { RetornoReverseGeocode } from "../../common/arcgis/dto/retorno-reverse-geocode";

export class Denuncia {
    codigo!: number;
    estado!: string;
    cidadeId!: number;
    cidade!: string;
    endereco!: string;
    numero!: string;
    complemento!: string;
    bairroId!: number;
    bairro!: string;
    pontoReferencia!: string;
    cep!: string;
    latitude!: string;
    longitude!: string;
    origem!: any;
    motivo!: any;
    complementoOcorrencia!: string;
    declaracaoVeracidade!: boolean;
    dataCadastro!: Date;
    protocolo!: string;
    origemOcorrencia!: number;
    tipo!: number;
    enderecoMapa!: string;
    latitudeMapa!: string;
    longitudeMapa!: string;
	cepMapa!: string;

    preencherCom(reverseGeocode: RetornoReverseGeocode): this {
        if (!reverseGeocode) {
            return this;
        }

        this.enderecoMapa = reverseGeocode.address?.LongLabel ?? '';
        this.latitudeMapa = reverseGeocode.address?.X?.toString() ?? '';
        this.longitudeMapa = reverseGeocode.address?.Y?.toString() ?? '';
        this.cepMapa = reverseGeocode.address?.Postal ?? '';

        return this;
    }
}