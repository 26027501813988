import { BreakpointObserver } from "@angular/cdk/layout";
import { AsyncPipe, CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, Component, OnInit } from "@angular/core";
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatStepperModule, StepperOrientation } from "@angular/material/stepper";
import { MatTooltipModule } from "@angular/material/tooltip";
import { ActivatedRoute, Router } from "@angular/router";
import { MessageService } from "primeng/api";
import { ButtonModule } from 'primeng/button';
import { Observable, map } from "rxjs";
import { NumbersOnlyDirective } from "src/app/components/common/directives/numbers-only.directive";
import { EnderecoSetor } from "src/app/components/common/entities/endereco-setor";
import { RegiaoAdministrativa } from "src/app/components/common/entities/regiao-administrativa";
import { FormComponent } from "src/app/components/common/util/form-component";
import { Formatador } from "src/app/components/common/util/formatador";
import { MapComponent } from "src/app/components/map/map.component";
import { MapService } from "src/app/components/map/map.service";
import { LoadingService } from "src/app/service/loading/loading-service.service";
import { Denuncia } from "../denuncia";
import { AtendimentoAnonimoService } from "../service/atendimento-anonimo.service";

@Component({
  selector: 'app-atendimento-anonimo',
  standalone: true,
  imports: [MatFormFieldModule, MatInputModule, FormsModule, MatButtonModule, MatIconModule, ReactiveFormsModule, MatStepperModule, MatRadioModule, AsyncPipe, CommonModule, MatSelectModule, MatCheckboxModule, NumbersOnlyDirective, MatTooltipModule, MapComponent, ButtonModule, MatAutocompleteModule],
  templateUrl: './atendimento-anonimo.component.html',
  styleUrls: ['./atendimento-anonimo.component.scss'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AtendimentoAnonimoComponent extends FormComponent implements OnInit {
  stepperOrientation: Observable<StepperOrientation>;
  localizacaoAtual!: GeolocationPosition;
  ras!: RegiaoAdministrativa[];
  rasFiltradas!: RegiaoAdministrativa[];
  setores!: EnderecoSetor[];
  setoresFiltrados!: EnderecoSetor[];
  localBarulho!: number;
  raSelecionada?: RegiaoAdministrativa;
  setorSelecionado?: EnderecoSetor;
  labelLocalSelecionado: string = '';
  exibirLabelLocalSelecionado: boolean = false;

  origem: any[] = [];
  motivo: any[] = [];

  exibindoMapa = false;

  textoBotaoMapa = 'Selecionar localização no mapa';

  constructor(private _fb: FormBuilder, private loader: LoadingService, breakpointObserver: BreakpointObserver, private service: AtendimentoAnonimoService, private messageService: MessageService, private route: ActivatedRoute, private mapService: MapService, private router: Router) {
    super(messageService);
    this.stepperOrientation = breakpointObserver.observe('(min-width: 650px)').pipe(map(({ matches }) => (matches ? 'horizontal' : 'vertical')));

    const timestamp = parseInt(route.snapshot.params['timestamp']);

    const agora = new Date().getTime();

    if (timestamp + 5000 < agora) this.router.navigate([`inicio`], { replaceUrl: true });
  }

  primeiroForm = this._fb.group({
    nome: [''],
    cpf: [''],
    rg: [''],
    email: [''],
    segundoEmail: [''],
    telefone: [''],
  });
  segundoForm = this._fb.group({
    cidade: ['', Validators.required],
    endereco: ['', Validators.required],
    numero: ['', Validators.required],
    complemento: [''],
    bairro: ['', Validators.required],
    bairroId: [''],
    pontoReferencia: [''],
  });
  terceiroForm = this._fb.group({
    origemOcorrencia: [null, Validators.required],
    tipo: [null, Validators.required],
  });
  quartoForm = this._fb.group({
    complementoOcorrencia: ['', [Validators.required, Validators.minLength(10)]],
  });
  resumoForm = this._fb.group({
    cidade: [''],
    endereco: [''],
    numero: [''],
    complemento: [''],
    bairro: [''],
    bairroId: [''],
    pontoReferencia: [''],
    longitude: [''],
    latitude: [''],
    origemOcorrencia: [''],
    tipo: [''],
    complementoOcorrencia: [''],
    declaracaoVeracidade: [false, Validators.required],
  });
  quintoForm = this._fb.group({});

  isLinear = true;

  ngOnInit(): void {
    this.loader.hide();
    this.listarRAs();

    this.segundoForm.controls.bairro.disable();

    this.terceiroForm.controls.origemOcorrencia?.valueChanges.subscribe(() => {
      this.terceiroForm.controls.tipo?.setValue(null);
    });

    this.segundoForm.controls.cidade.valueChanges.subscribe((nomeRA) => (this.rasFiltradas = this.filtrarRAs(nomeRA)));
    this.segundoForm.controls.bairro.valueChanges.subscribe((nomeSetor) => (this.setoresFiltrados = this.filtrarSetores(nomeSetor)));

    this.getOrigemDenuncia();
  }

  getOrigemDenuncia() {
    this.service.getOrigemDenuncia().subscribe({
      next: (data: any) => (this.origem = data),
      error: (error: any) => console.error('Erro ao capturar a localização.', error),
      complete: () => this.loader.hide(),
    });
  }

  buscarMotivo(origem: any) {
    if (origem.codigo) {
      this.service.getMotivoDenuncia(origem.codigo).subscribe({
        next: (data: any) => (this.motivo = data),
        error: (error: any) => console.error('Erro ao capturar a localização.', error),
        complete: () => this.loader.hide(),
      });
    }
  }

  listarRAs() {
    this.loader.show();

    this.service.getRAsDF().subscribe({
      next: (data: any) => {
        this.ras = data;
        this.rasFiltradas = this.ras;
      },
      error: (error: any) => {
        this.showErrorMessage('Cidade.', 'Erro ao buscar lista de cidades.');
        console.error('Erro ao buscar lista de cidades.', error);
      },
      complete: () => this.loader.hide(),
    });
  }

  private listarSetores() {
    if (!this.raSelecionada) {
      return;
    }

    this.loader.show();

    this.service.getSetoresDeRACom(this.raSelecionada.id).subscribe({
      next: (data: any) => {
        this.setores = data;
        this.segundoForm.controls.bairro.setValue('');
        this.segundoForm.controls.bairro.enable();
      },
      error: (error: any) => {
        this.showErrorMessage('Bairro', 'Erro ao buscar lista de bairros.');
        console.error('Erro ao buscar lista de bairros.', error);
      },
      complete: () => this.loader.hide(),
    });
  }

  private filtrarRAs(nomeRA: string | null): RegiaoAdministrativa[] {
    if (!nomeRA) {
      return this.ras;
    }
    return this.ras.filter((ra) => ra.descricao.toLowerCase().includes(nomeRA.toLowerCase()));
  }

  private filtrarSetores(nomeSetor: string | null): EnderecoSetor[] {
    if (!nomeSetor) {
      return this.setores;
    }
    return this.setores.filter((setor) => setor.stDescricao.toLowerCase().includes(nomeSetor.toLowerCase()));
  }

  complementoOcorrenciaBlur(event: FocusEvent) {
    this.quartoForm.controls.complementoOcorrencia.setValue(Formatador.iniciarMaiuscula(this.quartoForm.controls.complementoOcorrencia.value));
  }

  cidadeSelect(evento: MatAutocompleteSelectedEvent) {
    const nomeRaSelecionada: string = evento.option.value as string;
    this.selecionarCidade(nomeRaSelecionada);
  }

  setorSelect(evento: MatAutocompleteSelectedEvent) {
    const nomeSetorSelecionado: string = evento.option.value as string;
    return this.selecionarSetor(nomeSetorSelecionado);
  }

  private selecionarCidade(nomeRaSelecionada: string) {
    const ra = this.ras.find((ra) => ra.descricao.toLowerCase() == nomeRaSelecionada.toLowerCase());
    if (ra == undefined) {
      this.segundoForm.controls.bairro.disable();
      return;
    }
    this.raSelecionada = ra;
    if (this.raSelecionada) {
      this.listarSetores();
    }
  }

  private selecionarSetor(nomeSetorSelecionado: string) {
    this.setorSelecionado = this.setores.find((setor) => setor.stDescricao.toLowerCase() == nomeSetorSelecionado.toLowerCase()) as EnderecoSetor;
  }

  montarResumoForm() {
    this.formatarTextoDosCampos();

    const cidade: any = this.raSelecionada;
    const bairro: any = this.setorSelecionado;
    const origem: any = this.terceiroForm.controls.origemOcorrencia?.value;
    const tipo: any = this.terceiroForm.controls.tipo?.value;
    const complemento: any = this.quartoForm.controls.complementoOcorrencia?.value;

    this.resumoForm.controls.cidade.setValue(cidade.descricao);
    this.resumoForm.controls.endereco.setValue(this.segundoForm.controls.endereco?.value);
    this.resumoForm.controls.numero.setValue(this.segundoForm.controls.numero?.value);
    this.resumoForm.controls.complemento.setValue(this.segundoForm.controls.complemento?.value);
    this.resumoForm.controls.bairro.setValue(bairro.stDescricao);
    this.resumoForm.controls.pontoReferencia.setValue(this.segundoForm.controls.pontoReferencia?.value);
    this.resumoForm.controls.origemOcorrencia.setValue(origem.descricao);
    this.resumoForm.controls.tipo.setValue(tipo.descricao);
    this.resumoForm.controls.complementoOcorrencia.setValue(complemento);
  }

  enviar() {
    this.loader.show();

    let denuncia: Denuncia = new Denuncia();

    denuncia.cidade = this.raSelecionada?.descricao as string;
    denuncia.cidadeId = this.raSelecionada?.id as number;

    denuncia.bairro = this.setorSelecionado?.stDescricao as string;
    denuncia.bairroId = this.setorSelecionado?.id as number;

    const origem: any = this.terceiroForm.controls.origemOcorrencia?.value;
    denuncia.origemOcorrencia = origem?.codigo;

    const tipo: any = this.terceiroForm.controls.tipo?.value;
    denuncia.tipo = tipo?.codigo;

    denuncia.endereco = this.segundoForm.controls.endereco?.value ?? '';
    denuncia.numero = this.segundoForm.controls.numero?.value ?? '';
    denuncia.complemento = this.segundoForm.controls.complemento?.value ?? '';
    denuncia.pontoReferencia = this.segundoForm.controls.pontoReferencia?.value ?? '';

    denuncia.complementoOcorrencia = this.quartoForm.controls.complementoOcorrencia?.value ?? '';
    denuncia.declaracaoVeracidade = this.quartoForm.get('declaracaoVeracidade')?.value ?? false;

    denuncia.preencherCom(this.mapService.geocodeReverso);

    this.service.salvarDenuncia(denuncia).subscribe({
      next: (data: any) => {
        this.router.navigate([`externo/atendimento-web/sucesso/${data.protocolo}`], { replaceUrl: true });
      },
      error: (error: any) => {
        this.showErrorMessage('A denúncia não foi salva.', 'Erro ao inserir denúncia.');
        console.error('Erro ao capturar a localização.', error);
      },
      complete: () => this.loader.hide(),
    });
  }

  removerMensagem() {
    this.messageService.clear();
  }

  private formatarTextoDosCampos(): this {
    let enderecoFormatado: string = this.segundoForm.controls.endereco?.value?.toUpperCase() ?? '';
    let complementoFormatado: string = Formatador.toTitleCase(this.segundoForm.controls.complemento?.value);
    let referenciaFormatada: string = Formatador.iniciarMaiuscula(this.segundoForm.controls.pontoReferencia?.value);
    let dscAtendimentoFormatado: string = Formatador.iniciarMaiuscula(this.quartoForm.controls.complementoOcorrencia?.value);

    this.segundoForm.controls.endereco.setValue(enderecoFormatado);
    this.segundoForm.controls.complemento.setValue(complementoFormatado);
    this.segundoForm.controls.pontoReferencia.setValue(referenciaFormatada);
    this.quartoForm.controls.complementoOcorrencia.setValue(dscAtendimentoFormatado);

    return this;
  }

  disabledButtonMapa() {
    return this.isEmpty(this.segundoForm.controls.bairro.value) || this.isEmpty(this.segundoForm.controls.cidade.value) || this.isEmpty(this.segundoForm.controls.endereco.value) || this.isEmpty(this.segundoForm.controls.numero.value);
  }

  isEmpty(object: any) {
    return object === '' || object === undefined || object === null;
  }

  exibirMapa() {
    this.exibindoMapa = true;

    document.addEventListener('atualizao_endereco', () => {
      this.exibindoMapa = false;
      this.textoBotaoMapa = 'Alterar localização no mapa';
      this.labelLocalSelecionado = this.mapService.geocodeReverso.address?.LongLabel ?? '';
      this.exibirLabelLocalSelecionado = true;
      document.removeEventListener('atualizao_endereco', () => {});
    });
  }

  getSetorCentroidMapa(): EnderecoSetor {
    return this.setorSelecionado as EnderecoSetor;
  }

}