import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-internal-error',
  templateUrl: './internal-error.component.html',
  styleUrls: ['./internal-error.component.scss']
})
export class InternalErrorComponent {
  mensagem!: string;

  constructor(private route: ActivatedRoute) {
    this.mensagem = this.route.snapshot.paramMap.get('mensagem') || '';
  }
}
