import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToggleService } from '../app/components/common/header/toggle.service';
import { CustomizerSettingsService } from '../app/components/customizer-settings/customizer-settings.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
  title = 'Denúncia - 190';
  isToggled = false;
  isLoggedIn!: boolean;
  appIniciou = false;
  userRoles: string[] = [];

  isDark?: boolean;
  isCardBorder?: boolean;
  isCardBorderRadius?: boolean;
  isRTLEnabled?: boolean;
  isRightSidebar?: boolean;
  isHideSidebar?: boolean;

  constructor(public router: Router, private toggleService: ToggleService, public themeService: CustomizerSettingsService) {
    this.toggleService.isToggled$.subscribe((isToggled) => (this.isToggled = isToggled));
  }

  ngOnInit(): void {
    this.isDark = false;
    this.isCardBorder = this.themeService.isCardBorder();
    this.isCardBorderRadius = this.themeService.isCardBorderRadius();
    this.isRTLEnabled = this.themeService.isRTLEnabled();
    this.isRightSidebar = this.themeService.isRightSidebar();
    this.isHideSidebar = this.themeService.isHideSidebar();
  }

  ngAfterViewInit(): void {
    
  }

  toggleRightSidebarTheme() {
    this.themeService.toggleRightSidebarTheme();
  }

  toggleHideSidebarTheme() {
    this.themeService.toggleHideSidebarTheme();
  }

  toggleCardBorderTheme() {
    this.themeService.toggleCardBorderTheme();
  }

  toggleTheme() {
    this.themeService.toggleTheme();
  }

  toggleCardBorderRadiusTheme() {
    this.themeService.toggleCardBorderRadiusTheme();
  }

  toggleRTLEnabledTheme() {
    this.themeService.toggleRTLEnabledTheme();
  }
}