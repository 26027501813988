<div id="mapView" style="height: 100%; width: 100%;"></div>

<!-- <html>
    <body>
        <arcgis-map
          [view]="view"
          [map]="mapa"
          [center]="center"
          class="map-content"
          [zoom]="zoom"
          (arcgisViewPointerDown)="selecionarPonto($event)">





        </arcgis-map>
    </body>
</html> -->
<!-- <div style="height: 80vh; width: 100vw; padding: 5px;">
    <div class="map-wrapper" *ngIf="mapaCarregado">
        <arcgis-map
          [map]="mapa"
          graphics=""
          [center]="center"
          class="map-content"
          [zoom]="zoom"
          (click)="selecionarPonto($event)">


        </arcgis-map>
    </div>

</div> -->
