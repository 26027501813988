import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { InternalErrorComponent } from './components/common/internal-error/internal-error.component';
import { NotFoundComponent } from './components/common/not-found/not-found.component';
import { AtendimentoAnonimoComponent } from './components/atendimento/atendimento-anonimo/cadastro-atendimento-anonimo/atendimento-anonimo.component';
import { SucessoCadastroComponent } from './components/atendimento/atendimento-anonimo/cadastro-atendimento-anonimo/sucesso-cadastro/sucesso-cadastro.component';
import { InicioComponent } from './components/atendimento/inicio/inicio.component';
import { MapComponent } from './components/map/map.component';

const routes: Routes = [

    {path: '', redirectTo: 'inicio', pathMatch: 'full'},
    {path: 'inicio', component: InicioComponent},
    {path: 'externo/atendimento-web/:timestamp', component: AtendimentoAnonimoComponent},
    {path: 'externo/atendimento-web/sucesso/:protocolo', component: SucessoCadastroComponent},
    {path: 'info/:mensagem', component: InternalErrorComponent},
    {path: 'mapa', component: MapComponent},
    {path: '**', component: NotFoundComponent} // This line will remain down from the whole pages component list
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }